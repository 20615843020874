// переопределние, т.к. она объявлена устаревшей
// + она не генерирует цвет фона автоматически

@mixin bg-variant($parent, $color, $ignore-warning: false) {
    #{$parent} {
        background-color: $color !important;
        color: color-yiq($color);

        // old - неправильно работает когда внутри темного фона белый фон - текст белый и его не видно
        // $text-color: color-yiq($color);
        // @if $text-color == #fff {
        //     color: $text-color
        // }
    }
    a#{$parent},
    button#{$parent} {
        @include hover-focus() {
            background-color: darken($color, 10%) !important;
        }
    }
}