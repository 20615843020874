@import url(https://fonts.googleapis.com/css?family=Roboto:400,100,100italic,300,300ita‌​lic,400italic,500,500italic,700,700italic,900italic,900);
html, body, html * {
  font-family: 'Roboto', sans-serif;
}
ul.nav .nav-item.lvl-1 {
    margin-top: 1px;
}

ul.nav .nav-item.lvl-1.active a.lvl-1 {
    background: #44585e;
    border-left: 10px solid #32ffa9;
}

ul.nav .nav-item.lvl-1 a.lvl-1 {
    background: rgba(255,255,255,.1);
}
ul.nav.navbar-dark a {
    color: #fff;
}

ul.nav .nav-item.lvl-1.active .nav-item.lvl-2 {
    display: unset;
}

ul.nav .nav-item.lvl-2 {
    display: none;
}
::-webkit-scrollbar-thumb {
    border-width: 1px 1px 1px 2px;
    min-height: 70px;
    border-radius: 10px;
    border-color: #777;
    background-color: #b4d8c5;
}
::-webkit-scrollbar {
    width: 8px;
}

ul.nav .nav-item a:hover {
    color: #32ffa9;
}

ul.nav .nav-item.lvl-2.active a {
    color: #32ffa9;
}



/* fisrt page */

.blockPageContainer {
    padding: 30px 0 0 180px;
  }
  
  .toolbar {
    display: flex;
    width: 100%;
    font-size: 24px;
    color: #464655;
  }
  
  .blocksPage {
    border-bottom: solid 7px #F4F7FC;
    padding: 5px 10px 5px 0;
    color: #464655;
  }

  .blocksPage:hover {
     color: #7D90B2;
     text-decoration: unset;
  }
  
  .lettersPage {
    border-bottom: solid 7px #F4F7FC;
    padding: 5px 10px; 
  }
  
  .choosedPage {
    font-weight: bold;
    border-bottom: solid 7px #7D90B2;
    color: #464655;
  }
  
  
  .borderBottomToolbar {
    border-bottom: solid 7px #F4F7FC;
    width: 100%;
  }
  
  .blocksNav {
    margin: 30px 0;
    /* display: flex; */
    font-size: 16px;
  }
  
  .widget {
    display: flex;
    padding: 10px 20px;
    width: 20vw;
    justify-content: space-between;
    border: 1px solid #606F89;
    border-radius: 2px;
  }
  
  .blockCreatorBtn {
    margin: 0 30px;
    text-align: center;
    padding: 10px 70px;
    font-weight: bold;
    color: #FFFFFF;
    background: #31BE72;
    border-radius: 2px;
    cursor: pointer;
  }
  
  .blocks {
    margin: 70px 0 0 0;
  }
  
  .demarcationLine {
    display: flex;
    width: 100%;
    background-color:#F4F7FC;
    border-radius: 2px;
  }
  
  .demarcationLine span {
    font-size: 14px;
    padding: 0px 50px;
    border-radius: 2px;
    color: #FFFFFF;
  }
  
  .textBlockLine span {
    background-color: #31BE72;
  }
  
  .modelTableLine span {
    background-color: #7D90B2;
  }
  
  .buttonBlocksLine span {
    background-color: #ED0000;
    padding: 10px 80px;
  }
  
  .blocksContainer {
    margin: 0 130px 40px 0;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  
  .block {
    display: flex;
    background: #F4F7FC;
    box-shadow: 0px 4px 6px rgba(125, 144, 178, 0.15);
    border-radius: 3px;
    padding: 20px;
    justify-content: space-evenly;
    margin: 40px 30px 0 0;
  }
  
  .imgInfo {
    width: 250px;
    height: 270px;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    margin-right: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  .block-img {
    width: 250px;
    height: 270px;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    margin: 20px;
    /* margin-right: 60px; */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .info h3 {
    padding: 0 0 5px 0;
    font-weight: bold;
    font-size: 20px;
    color: #31BE72;
  }
  
  .info p {
    padding: 5px 0;
    font-weight: bold;
    color: #464655;
  }
  
  .info li { 
    list-style-position: inside;
  }
  
  .info li a {
    color: #3B3A4A;
    text-decoration: none;
    line-height: 150%;
  }
  
  .info li a:hover {
    color: #31BE72;
    text-decoration: underline;
  }
  
  .expand {
    margin-top: 15px;
    border-top: 2px solid #DAE1EC;
    padding: 10px 0;
    font-weight: bold;
    color: #31BE72;
    cursor: pointer;
  }
  

  /* Style the form - display items horizontally */
.form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  }
  
  /* Add some margins for each label */
  .form-inline label {
    margin: 5px 10px 5px 0;
  }
  
  /* Style the input fields */
  .form-inline input {
    vertical-align: middle;
    margin: 5px 10px 5px 0;
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
  }
  
  /* Style the submit button */
  .form-inline button {
    padding: 10px 20px;
    background-color: dodgerblue;
    border: 1px solid #ddd;
    color: white;
  }
  
  .form-inline button:hover {
    background-color: royalblue;
  }
  
  /* Add responsiveness - display the form controls vertically instead of horizontally on screens that are less than 800px wide */
  @media (max-width: 800px) {
    .form-inline input {
      margin: 10px 0;
    }
  
    .form-inline {
      flex-direction: column;
      align-items: stretch;
    }
  }



.letter {
  display: flex;
  background: #F4F7FC;
  box-shadow: 0px 4px 6px rgba(125, 144, 178, 0.15);
  border-radius: 3px;
  color: #3B3A4A;
  overflow: hidden;
  justify-content: space-between;
  flex: 1 0 auto;
}

.title-mail { 
  font-weight: bold;
  font-size: 20px;
}

.imgInfo {
  width: 142px;
  height: 154px;
  margin-left: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  cursor: pointer;
}

.letter-title {
  background: #31BE72;
  border-radius: 2px;
  font-size: 14px;
  padding: 2px 65px;
  color: #fff;
}

.stats-amount{
  font-weight: normal;
    font-size: 12px;
    color: #7D90B2;
 }

 .hide-link {
  color: #31BE72;
  font-family: Roboto;
  font-weight: bold;
  font-size: 16px;
 }

 .link-block__mail {
  color: #3B3A4A;
  font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 150%;
/* or 24px */
 }
 .link-block__mail:hover {
  color: #31BE72;
   text-decoration: none;

 }

 .title-block {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 20px;
  /* identical to box height, or 20px */
  color: #31BE72;
 }

 .w-85 {
  width: 85% !important;
}

.toolbar-title {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 78px;
  color: #464655;
}

.block-uses {
  color: #2E3B52;
  font-size: 16px;
  line-height: 22px;
}

.delete-block {
  font-size: 16px;
  line-height: 78px;
  /* or 52px */

  text-decoration-line: underline;

  color: #F13B3B;
}

.back-block {
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 78px;
  align-items: center;
  color: #464655;
}

.mx-11 {
  margin-left: 11rem !important;
}

.form-group input {
  font-weight: 600;
}
.form-group input::placeholder {
  font-weight: 100;
}